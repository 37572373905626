import $ from "jquery";

//not ashamed , will refactor in react one day.
$("#mol-form").submit((ev) => {
	ev.preventDefault();
	
	$("#result").slideUp();
	$("#result .result-zone").empty();

	const sales = $("#mol-form-sales").val();
	const stores = $("#mol-form-lojas").val();
	const sow = $("#mol-form-state-of-work").val();
	const cicle = 4;

	let buy = (sales * (sow /100));
	let minSalesDay = parseFloat((buy / (cicle * 30))/stores).toFixed(1);

	if(Number.isNaN(buy) || Number.isNaN(minSalesDay)) {
		alert("Por favor, preencha corretamente o formulário acima.")
	} else  {
			const template = (cicle, sow_conversion, buy, minSalesDay) => `
	          <div class="form-row">
		          <div class="form-label">
		            <h3>Ciclo de venda sugerido (meses):</h3>
		            <h4>${cicle} meses</h4>
		          </div>
		        </div>
		        <div class="form-row">
		          <div class="form-label">
		            <h3>Sugestão de quantidade:</h3>
		            <h4>${Math.round(buy)} Unidades</h4>
		          </div>
		        </div>
		        <div class="form-row">
		          <div class="form-label">
		            <h3>Venda mínima necessária por dia, por loja, para zerar o estoque:</h3>
		            <h4>${Math.round(minSalesDay)} unidades</h4>
		          </div>
		       </div>
		`

		

		const result = template(cicle, sow, buy, minSalesDay);

		$("#result .result-zone").empty();
		$("#result .result-zone").append(result);
		$("#result").slideDown();
		$('html, body').animate({
        	scrollTop: $("#result").offset().top
    	}, 2000);
	}
})